/* eslint-disable */
;(function () {
  var AltenarSportsbookV1 = function (_containerId, _initParams) {
    _initParams = _initParams || {}
    var checkAndCallPlatformCallback = function (_callbackName, _param) {
      var hasCallback = false
      if (typeof _initParams[_callbackName] === 'function') {
        _initParams[_callbackName](_param)
        hasCallback = true
      }
      return hasCallback
    }
    if (
      (window.location.search || '')
        .toLowerCase()
        .indexOf('error=cookiepolicy') > -1
    ) {
      console.log('Cookies are disabled')
      checkAndCallPlatformCallback('cookieserrorcallback', {})
      return {}
    }
    // variable for build version
    // var buildVersion = 'currentBuildVersion'
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    var triggerStateChangeCallback = true
    // for old customers who use own iframe
    var extendInitParamsWithSRC = function (_string) {
      var pairs =
        ((((_string || '').split('?') || [])[1] || '').split('#') || [])
          .join('&')
          .split('&') || []
      for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=')
        _initParams[pair[0]] = pair[1] || null
      }
    }

    var iframe = document.createElement('iframe')
    var defaultIframeUrl = 'https://sb1client-altenar.biahosted.com'

    var postIFrameMessage = function (method, params) {
      iframe &&
        iframe.contentWindow &&
        iframe.contentWindow.postMessage(
          { method: method, params: params },
          _initParams.url || defaultIframeUrl
        )
    }

    // postload params for altenar app
    var postloadParams = {
      // it duplicates params in queryParams
      params: ['betslipoddids', 'betreservationcode', 'betid', 'banners'],
      getParams: function () {
        var resultParams = {}
        for (var idx = 0; idx < this.params.length; idx++) {
          var paramName = this.params[idx]
          resultParams[paramName] = _initParams[paramName]
        }
        return resultParams
      },
      sendIFrameMessage: function () {
        var params = this.getParams()
        postIFrameMessage('postLoad', params)
      },
      hasParam: function (_value) {
        return this.params.indexOf(_value) > -1
      },
    }

    // dimensions params for altenar app
    var dimensionsParams = {
      // it duplicates params in queryParams
      params: ['fixedtop', 'fixedbottom'],
      getParams: function () {
        var resultParams = {}
        var iframeRect = iframe.getBoundingClientRect()
        var iframeBottom = iframeRect.bottom
        var iframeTop = iframeRect.top
        for (var idx = 0; idx < this.params.length; idx++) {
          var paramName = this.params[idx]
          var platformIndent = parseFloat(_initParams[paramName]) || 0
          var paramValue = 0
          switch (paramName) {
            case 'fixedtop':
              if (platformIndent > iframeTop) {
                paramValue = platformIndent - iframeTop
              }
              break
            case 'fixedbottom':
              if (platformIndent > window.innerHeight - iframeBottom) {
                paramValue = iframeBottom - window.innerHeight + platformIndent
              }
              break
          }
          resultParams[paramName] = paramValue
        }
        resultParams['innerHeight'] = window.innerHeight
        return resultParams
      },
      sendIFrameMessage: function () {
        var params = {}
        if (!_initParams.isfixed) {
          params = this.getParams()
        }
        postIFrameMessage('changeDimensions', params)
      },
      hasParam: function (_value) {
        return this.params.indexOf(_value) > -1
      },
    }
    // lists query params
    var queryParams = {
      params: [
        'token',
        'accesstoken',
        'skinid',
        'walletcode',
        'lang',
        'configid',
        'ishybridmode',
        'isfixed',
        'fixedtop',
        'fixedbottom',
        'staticorigin',
        'instance',
        'betslipoddids',
        'betreservationcode',
        'timezoneoffset',
        'numformat',
        'hasplacebetplatformerrorcallback',
        'oddstype',
        'special',
        'virtualsports',
        'ishashclassesmode',
        'betreuseurltemplate',
        'authrefreshtimeout',
      ],
      getQueryParams: function () {
        var stringQuery = ''
        for (var paramName of Object.keys(_initParams)) {
          if (this.params.indexOf(paramName) > -1) {
            var isNull = _initParams[paramName] === null
            var isUndefined = _initParams[paramName] === undefined
            if (!isNull && !isUndefined) {
              if (stringQuery !== '') {
                stringQuery += '&'
              }
              stringQuery +=
                paramName + '=' + encodeURIComponent(_initParams[paramName])
            }
          }
        }
        stringQuery = stringQuery !== '' ? '?' + stringQuery : stringQuery
        if (isSafari) {
          stringQuery += '&r=' + new Date().getTime()
        }
        stringQuery +=
          '&hasplacebetplatformerrorcallback=' +
          (typeof _initParams['placebetplatformerrorcallback'] === 'function')
        return stringQuery
      },
    }

    /*
          prelive - default
    
          prelive
          prelive/sport/{SPORT-ID}
          prelive/{SPORT-IDS}/{CAT-IDS}/{CHAMP-IDS}
          prelive/details/{DETAILS-ID}
          prelive/details/{DETAILS-ID}/{SPORT-IDS}/{CAT-IDS}/{CHAMP-IDS}
    
          live
          live/sport/{SPORT-ID}
          live/{SPORT-IDS}/{CAT-IDS}/{CHAMP-IDS}
          live/details/{EVENT-ID}
          live/details/{DETAILS-ID}/{SPORT-IDS}/{CAT-IDS}/{CHAMP-IDS}
    
          and any page that will be settled in page
        */

    var hashParams = {
      params: [
        // live, prelive
        'page',
        // id - '234124'
        'eventid',
        // events groups
        'sportids',
        'catids',
        'champids',
        'marketgroupid',
        // mybets
        'betid',
        // for open in betreuseid
        'betreuseid',
      ],
      getHashParams: function () {
        var hash = '#/'
        var pageName = (_initParams[this.params[0]] || 'prelive').toLowerCase()
        var isSportContent = pageName === 'prelive' || pageName === 'live'
        var eventDetails = this.getEventDetailsHash()
        if (isSportContent && eventDetails) {
          hash += eventDetails
        } else if (_initParams['betreuseid']) {
          hash =
            '#/' +
            'betReuse' +
            '/' +
            _initParams['betreuseid'] +
            '/isSinglePage'
        } else {
          var eventsGroupsHash = this.getEventsGroupsHash()
          hash += pageName
          var mybets = pageName === 'mybets'
          if (mybets && _initParams.betid) {
            hash = '#/' + 'mybets' + '/' + _initParams.betid
          } else if (eventsGroupsHash) {
            if (pageName === 'prelive' && !this.isOnlySport()) {
              hash = '#/tree/all/' + eventsGroupsHash + 'odds'
            } else if (pageName === 'competitions') {
              hash = '#/' + eventsGroupsHash + '/competitions/all'
            } else if (pageName === 'coupons') {
              hash = '#/' + eventsGroupsHash + '/coupons'
            } else if (isSportContent) {
              hash += '/' + eventsGroupsHash
            } else {
              hash += '/'
            }
          }
        }
        var lastSymbol = hash[hash.length - 1]
        if (lastSymbol === '/') {
          hash = hash.slice(0, hash.length - 1)
        }
        return hash
      },
      getPageNameHash: function () {
        var hash = ''
        var hashParams = this
        var pageName = _initParams[this.params[0]] || 'prelive'

        switch (pageName) {
          case 'prelive':
            hash += 'prelive/' + hashParams.getEventsGroupsHash()
            break
          case 'live':
            hash += 'live/' + hashParams.getEventsGroupsHash()
            break
          default:
            hash += pageName
            break
        }

        return hash
      },
      getEventDetailsHash: function () {
        var eventId = _initParams[this.params[1]]
        var pageName = _initParams[this.params[0]]
        if (eventId) {
          var hash
          var sportIds = _initParams.sportids || '0'
          var catIds = _initParams.catids || '0'
          var champIds = _initParams.champids || '0'
          if (pageName === 'live') {
            hash = 'live/event/' + sportIds + '/'
          } else {
            hash = 'event/' + sportIds + '/' + catIds + '/' + champIds + '/all/'
          }
          hash +=
            eventId +
            '/' +
            (_initParams['marketgroupid'] ? _initParams['marketgroupid'] : '')
          return hash
        } else {
          return false
        }
      },
      isOnlySport: function () {
        var onlySport
        onlySport =
          _initParams.sportids && !(_initParams.catids || _initParams.champids)
        return onlySport
      },
      getEventsGroupsHash: function (_forCompetitions) {
        var hash = ''
        var params = this.params
        var isEventGroupNotEmpty = false

        if (this.isOnlySport() && !_forCompetitions) {
          return 'sport/' + _initParams.sportids
        }
        // iteration in the events groups
        for (var i = 2; i <= 4; i++) {
          hash += addCatHash(i)
        }

        hash += _initParams['marketgroupid']
          ? _initParams['marketgroupid'] + '/'
          : '0/'
        return isEventGroupNotEmpty ? hash : ''

        function addCatHash(_index) {
          var sportsCategory = _initParams[params[_index]]
          if (sportsCategory) {
            isEventGroupNotEmpty = true
            return sportsCategory + '/'
          } else {
            return '0/'
          }
        }
      },
    }

    var setIframeSrc = function () {
      var url = _initParams.url || defaultIframeUrl
      var query = queryParams.getQueryParams()
      var hash = hashParams.getHashParams()
      iframe.src = url + query + hash
    }

    var onWindowScroll = function (_event) {
      dimensionsParams.sendIFrameMessage()
    }
    var onWindowResize = function (_event) {
      dimensionsParams.sendIFrameMessage()
    }
    var onIframeMessage = function (_event) {
      var data = _event.data || {}
      switch (data.type) {
        case 'scroll':
          if (
            window.scrollY > container.offsetTop &&
            !_initParams.isfixed &&
            !checkAndCallPlatformCallback('scrollcallback', {
              x: data.scroll.x,
              y: data.scroll.y + container.offsetTop,
            })
          ) {
            window.scrollTo(data.scroll.x, data.scroll.y + container.offsetTop)
          }
          break
        case 'resize':
          if (
            !_initParams.isfixed &&
            !checkAndCallPlatformCallback('resizecallback', data.rect)
          ) {
            iframe.height = data.rect && data.rect.height
          }
          dimensionsParams.sendIFrameMessage()
          break
        case 'load':
          dimensionsParams.sendIFrameMessage()
          checkAndCallPlatformCallback('loadcallback', data.hash)
          setParam('banners', _initParams.banners)
          break
        case 'refreshcookies':
          if (
            (window.location.search || '')
              .toLowerCase()
              .indexOf('retry=cookiepolicy') > -1
          ) {
            console.log('Third party cookies are disabled')
            checkAndCallPlatformCallback('cookieserrorcallback', {})
            destroy()
            return
          }
          if (!checkAndCallPlatformCallback('refreshcookiescallback', {})) {
            window.location.href =
              defaultIframeUrl +
              '/home/init?url=' +
              encodeURIComponent(
                window.location.origin +
                  window.location.pathname +
                  window.location.search
              )
          }
          break
        case 'hashchange':
          checkAndCallPlatformCallback('hashchangecallback', data.hash)
          break
        case 'statechange':
          if (triggerStateChangeCallback) {
            checkAndCallPlatformCallback('statechangecallback', data.params)
          } else {
            triggerStateChangeCallback = true
          }
          break
        case 'redirecttolive':
          checkAndCallPlatformCallback('redirecttolivecallback', data.eventId)
          break
        case 'redirecttoprelive':
          checkAndCallPlatformCallback(
            'redirecttoprelivecallback',
            data.eventId
          )
          break
        case 'insufficientbalance':
          checkAndCallPlatformCallback(
            'insufficientbalancecallback',
            data.totalStake
          )
          break
        case 'showeventdetails':
          checkAndCallPlatformCallback('showeventdetailscallback', data.eventId)
          break
        case 'showevents':
          checkAndCallPlatformCallback('showeventscallback', data.events)
          break
        case 'showloginbox':
          checkAndCallPlatformCallback('showloginboxcallback')
          break
        case 'betslipoddscountchange':
          checkAndCallPlatformCallback(
            'betslipoddscountchangecallback',
            data.selectionIds
          )
          break
        case 'sportsmenu':
          checkAndCallPlatformCallback('sportsmenucallback', data.sports)
          break
        case 'placebet':
          checkAndCallPlatformCallback('placebetcallback', data.placeBet)
          break
        case 'accesstoken':
          _initParams.accesstoken = data.accesstoken
          break
        case 'logoff':
          if (!checkAndCallPlatformCallback('logoffcallback')) {
            window.location.reload()
          }
          break
        case 'placebetplatformerror':
          checkAndCallPlatformCallback(
            'placebetplatformerrorcallback',
            data.CallbackParameter
          )
          break
        case 'betReservation':
          checkAndCallPlatformCallback(
            'betreservationcallback',
            data.reservationCode
          )
          break
        case 'promobet':
          checkAndCallPlatformCallback('promobetcallback', data.promoBet)
          break
        case 'getEventDetailsAdditionalInfo':
          checkAndCallPlatformCallback(
            'geteventdetailsadditionalinfocallback',
            data.externalInfo
          )
      }
    }

    var prepareInitParams = function () {
      convertInitParamsToLowercase()
      convertInitParamsToStrings()
    }

    var convertInitParamsToLowercase = function () {
      var newParams = {}
      var keyLowerCase
      for (var key of Object.keys(_initParams)) {
        keyLowerCase = key.toLowerCase()
        newParams[keyLowerCase] = _initParams[key]
      }
      _initParams = newParams
    }

    var convertInitParamsToStrings = function () {
      for (var key of Object.keys(_initParams)) {
        if (_initParams[key] && typeof _initParams[key] === 'object') {
          if (Array.isArray(_initParams[key])) {
            _initParams[key] = _initParams[key].join(',')
          } else {
            _initParams[key] = JSON.stringify(_initParams[key])
          }
        }
      }
    }

    var setParams = function (_params, extendedParams) {
      var paramsKeys = Object.keys(_params)
      for (var i = 0; i < paramsKeys.length; i++) {
        var key = paramsKeys[i]
        this.setParam(key, _params[key], extendedParams)
      }
    }

    var setParam = function (param, value, extendedParams) {
      var paramInLowerCase = param.toLowerCase()
      _initParams[paramInLowerCase] = value
      prepareInitParams()
      var triggerCallback = extendedParams && extendedParams.triggerCallback
      if (triggerCallback === false) {
        triggerStateChangeCallback = triggerCallback
      }
      if (postloadParams.hasParam(paramInLowerCase)) {
        postloadParams.sendIFrameMessage()
      } else if (dimensionsParams.hasParam(paramInLowerCase)) {
        dimensionsParams.sendIFrameMessage()
      } else {
        setIframeSrc()
      }
    }
    var destroy = function () {
      window.removeEventListener('hashchange', setIframeSrc)
      window.removeEventListener('message', onIframeMessage)
      window.removeEventListener('scroll', onWindowScroll)
      window.removeEventListener('resize', onWindowResize)
      if (iframe && iframe.parentNode) {
        iframe.parentNode.removeChild(iframe)
        iframe = null
      }
    }
    var logout = function () {
      _initParams.token = ''
      _initParams.accesstoken = ''
      setIframeSrc()
    }
    var getParams = function () {
      // all parameters non-available in the docs
      var getMatchedParams = function (params) {
        var matchedParams = []
        for (var key of Object.keys(params)) {
          if (internalParams.indexOf(params[key]) === -1) {
            matchedParams.push(params[key])
          }
        }
        return matchedParams
      }
      var internalParams = [
        'accesstoken',
        'ishybridmode',
        'staticorigin',
        'instance',
        'hasplacebetplatformerrorcallback',
        'oddstype',
        'special',
        'ishashclassesmode',
      ]
      var params = getMatchedParams(queryParams.params).concat(
        getMatchedParams(hashParams.params)
      )
      return params
    }
    var container = document.querySelector(_containerId)

    // for old customers who use own iframe
    // find iframe, get its params and replace it with new container
    if (container && container.tagName.toLowerCase() === 'iframe') {
      var nextSibling = container.nextSibling
      var parentNode = container.parentNode
      extendInitParamsWithSRC(container.attributes.src.value)
      var newContainer = document.createElement('div')
      newContainer.className = container.className
      newContainer.id = container.id
      container.remove()
      parentNode.insertBefore(newContainer, nextSibling)
      container = newContainer
    }
    //
    //

    prepareInitParams()
    window.addEventListener('hashchange', setIframeSrc)
    window.addEventListener('message', onIframeMessage)
    window.addEventListener('scroll', onWindowScroll)
    window.addEventListener('resize', onWindowResize)
    _initParams.token = _initParams.token || ''
    setIframeSrc()
    _initParams.token = null
    iframe.style.border = '0px'
    iframe.style.width = '1px'
    iframe.style.minWidth = '100%'
    iframe.style.minHeight = '100vh'
    iframe.scrolling = (_initParams.isfixed && 'yes') || 'no'

    // set fields
    this.error = ''
    if (container) {
      container.appendChild(iframe)
      this.iframe = iframe
      this.setParam = setParam
      this.setParams = setParams
      this.getParams = getParams
      this.destroy = destroy
      this.logout = logout
    } else {
      this.error = 'container not found'
      console.warn(this.error)
    }
  }

  if (!window.AltenarSportsbookV1) {
    AltenarSportsbookV1.version = '2.560'
    window.AltenarSportsbookV1 = AltenarSportsbookV1
  }
})()
