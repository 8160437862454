import React, { useEffect, useContext, useState } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'

import Layout from '../../components/common/layout'
import { getSportsbookToken } from '../../adapters/user'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import { AuthContext } from '../../context/authProvider'
import storageService from '../../services/storageService'
import { EnvConfig } from '../../config/EnvConfig'
import media from '../../components/css/media'

const SportsContainer = styled.div`
  padding: 0;
  background-color: #1e1e1e;

  ${media.desktop`
    padding: 1rem;
  `};
`

const OldBets = () => {
  const { isMobile } = useDeviceDetect()
  const { isLoggedIn } = useContext(AuthContext)

  const [token, setToken] = useState(null)
  const user = storageService.getUser()

  const intl = useIntl()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.location.hostname.indexOf('kto.com') > 0) {
        require('../../externalScripts/AltenarV1ProdSB.js')
      } else {
        require('../../externalScripts/AltenarV1StageSB.js')
      }
    }
  }, [])

  useEffect(() => {
    let ASb = null

    const sportsLanguages = {
      en: 'en-GB',
      fi: 'fi',
      no: 'no',
      ca: 'en-ca',
      pe: 'es-ES',
      ie: 'en',
      nz: 'en',
      fr: 'fr',
      cl: 'es',
      mx: 'es',
      pl: 'en',
      pt: 'pt-BR',
    }

    const initializeSportsbook = (token, walletCode) => {
      if (typeof window !== 'undefined') {
        if (window.AltenarSportsbookV1) {
          let initParams = {
            isfixed: false,
            fixedtop: isMobile ? 50 : 60,
            fixedbottom: 0,
            timezoneOffset: new Date().getTimezoneOffset(),
            oddstype: 'decimal',
            page: 'mybets',
            skinId: 'kto.com',
            lang: sportsLanguages[intl.locale],
          }

          if (token !== undefined) {
            initParams = Object.assign(initParams, {
              token: token,
              walletcode: walletCode,
            })
          }

          ASb = new window.AltenarSportsbookV1(
            '#old-bets-container',
            initParams
          )
        }
      }
    }

    const fetchToken = async () => {
      const response = await getSportsbookToken()
      if (response.ok) {
        if (ASb) {
          ASb.setParam('token', response.data, true)
          ASb.setParam('walletcode', EnvConfig.SPORTS_WALLET_CODE, true)
        } else {
          initializeSportsbook(response.data, EnvConfig.SPORTS_WALLET_CODE)
        }

        setToken(response.data)
      }
    }

    if (isLoggedIn && !token) fetchToken()
    if (!isLoggedIn && !user) initializeSportsbook()
  }, [isLoggedIn, token, user, intl.locale, isMobile])

  return (
    <Layout>
      <SportsContainer>
        <div id='old-bets-container' />
      </SportsContainer>
    </Layout>
  )
}

export default OldBets
